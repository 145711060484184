
import {
  computed,
  defineComponent,
  ref
} from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import ComposeSuccessButtons from '@/components/pages/compose/ComposeSuccessButtons.vue'
import SmsSentDetailsSingle from '@/components/pages/history/smsSent/details/SmsSentDetailsSingle.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { SMSReceivedSent } from '@/definitions/history/types'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'

const breadcrumbsLink: BreadcrumbsLink[] = [
  {
    label: 'Compose',
    name: 'base.compose',
  },
  {
    label: 'Sent text message',
  },
]

export default defineComponent({
  components: {
    PageBlock,
    ComposeSuccessButtons,
    SmsSentDetailsSingle,
    TmAlert,
  },
  setup() {
    const showAlert = ref(true)
    const message = computed(() => (getTableData('historySMS') as SMSReceivedSent[])[0])

    return {
      breadcrumbsLink,
      showAlert,
      message,
      formatDate,
    }
  },
})
